/**
 * Global
 */
@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  margin-top: 4rem;

  @media (min-width: $md) {
    margin-top: 6rem;
  }
}

.headline-start {
  position: static;
  color: $primary;

  @media (min-width: $sm) {
    position: absolute;
    left: 5rem;
    bottom: 30%;
    color: $white;
  }

  h1 {
    text-transform: uppercase;
  }
}

.box {
  -webkit-box-shadow: 5px 5px 16px 4px #bfbfbf;
  box-shadow: 5px 5px 16px 4px #bfbfbf;

  img {
    margin: 0;
  }
}

.notfall-hotline {
  position: fixed;
  bottom: 3rem;
  right: 2rem;
  background-color: $notfallrot;
  padding: 0.1rem 0.4rem 1.5rem 1.75rem;
  color: $white;
  width: 275px;
  z-index: 11;
  display: none;

  @media (min-width: $md) {
    display: block;
  }
}

.notfall-btn {
  position: fixed;
  z-index: 8;
  bottom: 4rem;
  right: 4rem;
  box-shadow: 4px 8px 25px #f57e69;
  border-radius: 50% !important;
}

.arm-ani {
  position: relative;
}

.arm-ani::before {
  background-image: url('../img/kompressoren_pumpen_zentrale_kpz_arm.png');
  content: '';
  position: absolute;
  right: 0;
  bottom: -154px;
  background-size: cover;
  width: 94px;
  height: 74px;
  z-index: 7;
  animation: thumbup 2s infinite;
}

@keyframes thumbup {
  0% {
    transform: rotate(0);
  }

  30% {
    transform: rotate(30deg);
  }

  70% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(0);
  }
}

.center-right-aligned-text div {
  display: flex;
  flex-direction: column;
}

.checkmark {
  position: relative;
}

.checkmark::before {
  position: absolute;
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../img/kpz_checkmark_gelb.svg');
  width: 40px;
  height: 40px;
  left: 0;
  top: 0;
}

.standort,
.mail,
.phone,
.zeit {
  position: relative;
  padding-left: 2rem;
}

.standort::before,
.mail::before,
.zeit::before,
.phone::before {
  position: absolute;
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  left: 0;
  top: 5px;
  z-index: 9;
}

.standort::before {
  background-image: url('../img/kpz_icon_blau_standort.svg');
}

.zeit::before {
  background-image: url('../img/kpz_icon_blau_zeit.svg');
}

.phone::before {
  background-image: url('../img/kpz_icon_blau_phone.svg');
}

.mail::before {
  background-image: url('../img/kpz_icon_blau_mail.svg');
}

.m-auto-i {
  margin-left: auto !important;
  margin-right: auto !important;
}

.gap figure {
  margin-bottom: 2.5rem !important;
}

.mustermann {
  margin: 2rem auto;
  max-width: fit-content;

  p {
    max-width: 12rem;
  }

  @media (min-width: $md) {
    margin: 2rem 0;
  }
}

.max-w-9 {
  max-width: 11rem;
}

.width-100 {
  @media (max-width: $xs) {
    img {
      width: 100% !important;
    }
  }
}

iframe {
  max-width: 100% !important;

  @media (max-width: $xs) {
    max-height: 250px;
  }
}

.flex-basis-50 {
  @media (max-width: $md) {
    flex-basis: 50% !important;
  }
}

.width-img-100 {
  img {
    width: 100% !important;
  }
}
