$xs: 600px;
$sm: 782px;
$md: 1024px;
$lg: 1220px;
$xl: 1460px;

section,
.section {
  padding: 2rem 1rem 4rem 1rem;
  margin: auto;

  @media (min-width: breakpoint(sm)) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 0;
  }

  @media (min-width: breakpoint(lg)) {
    padding-left: 11rem;
  }

  @media (min-width: breakpoint(xl)) {
    max-width: 1460px;
    margin: auto;
  }
}

.section-x {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $md) {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0;
    padding-right: 0;
  }
}

.section-x-klein {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $md) {
    margin-left: 7%;
    margin-right: 7%;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media (min-width: $lg) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.section-x2 {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $md) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media (min-width: $lg) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

.section-kontakt {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;

  @media (min-width: breakpoint(sm)) {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: breakpoint(md)) {
    margin-left: 11rem;
    margin-right: 5rem;
  }

  @media (min-width: breakpoint(xl)) {
    max-width: 1260px;
    margin: auto;
  }
}

.section-full {
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;

  @media (min-width: breakpoint(md)) {
    padding-left: 5%;
    padding-right: 5%;
  }

  @media (min-width: breakpoint(xl)) {
    max-width: 1460px;
    padding-left: 3rem;
    padding-right: 0;
  }
}

.ml-0-i {
  @media (max-width: $sm) {
    margin-left: 0 !important;
  }
}

.flex-basis-0 {
  @media (max-width: $sm) {
    flex-basis: 0;
  }
}