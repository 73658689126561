// *
// * Include Fonts and define basic font ajustments and styling
// *

$fonts-path: '../fonts';

/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/noto-sans-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/noto-sans-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/noto-sans-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/noto-sans-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/noto-sans-v14-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}

/* noto-sans-700 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-sans-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/noto-sans-v14-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/noto-sans-v14-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/noto-sans-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/noto-sans-v14-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/noto-sans-v14-latin-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}

/* noto-sans-tc-regular - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-tc-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/noto-sans-tc-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/noto-sans-tc-v11-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/noto-sans-tc-v11-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/noto-sans-tc-v11-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/noto-sans-tc-v11-latin-regular.svg#NotoSansTC') format('svg'); /* Legacy iOS */
}

/* noto-sans-tc-700 - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-sans-tc-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/noto-sans-tc-v11-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/noto-sans-tc-v11-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/noto-sans-tc-v11-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/noto-sans-tc-v11-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/noto-sans-tc-v11-latin-700.svg#NotoSansTC') format('svg'); /* Legacy iOS */
}

/* noto-sans-tc-900 - latin */
@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/noto-sans-tc-v11-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/noto-sans-tc-v11-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/noto-sans-tc-v11-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/noto-sans-tc-v11-latin-900.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/noto-sans-tc-v11-latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/noto-sans-tc-v11-latin-900.svg#NotoSansTC') format('svg'); /* Legacy iOS */
}

.font-brand {
  font-family: sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Noto Sans', sans-serif;
  font-weight: 700 !important;
  letter-spacing: -1px;
  line-height: 1;
}

p {
  font-family: 'Noto Sans TC', sans-serif;
}

.is-style-heading-size-1,
.heading-size-1 {
  @include font-size(2.5rem);
}

.is-style-heading-size-2,
.heading-size-2 {
  @include font-size(1.5rem);
}

.is-style-heading-size-3,
.heading-size-3 {
  @include font-size(2rem);
}

.is-style-heading-size-4,
.heading-size-4 {
  @include font-size(1.5rem);
}

.is-style-heading-size-5,
.heading-size-5 {
  @include font-size(1.2rem);
}
