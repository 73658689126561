/**
* Gutenberg Color helper classes
* - rememer to set colors for backend
*/
@each $color-name, $color-value in $colors {
  .has-#{'' + $color-name}-color {
    color: $color-value;
  }

  .has-#{'' + $color-name}-background-color {
    background-color: $color-value;
  }
}

/**
* Container settings for Gutenberg Markup
*/

// force center for some gutenberg blocks

.wp-block-group__inner-container {
  margin-left: auto !important;
  margin-right: auto !important;
}

.alignfull {
  max-width: 100%;
}

.wp-block-columns {
  flex-wrap: wrap;
}

/**
* Gutenberg Blocks Styling
*/
