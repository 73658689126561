/**
 * Header
 */

// Logo size
.main-logo-link {
  display: none;
  align-items: center;
  height: 100%;

  @media (min-width: $md) {
    display: flex;
  }
}

.menu-item-21 {
  @media (min-width: $sm) {
    margin-left: 3rem;
    color: map-get($colors, 'white');
    background-color: map-get($colors, 'primary');
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-height: 2.4rem;
    align-self: center;
  }
}

/* mobile menue */
.mobile-menu {
  position: fixed;
  top: 3.5rem;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: $primary;
  color: $white;
}

.mobile-btn {
  display: inline-block;
  cursor: pointer;
  width: auto;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: $primary;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.desktop-nav {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  margin: 0;
  box-shadow: 2px 13px 9px -5px rgb(0 0 0 / 35%);
}

.current_page_item a {
  border-bottom: 2px solid $gelb;
  padding-bottom: 2px;
}

nav,
.wp-block-button,
.phone,
.mail {
  a:hover {
    transform: scale(1.1) !important;
    opacity: 0.8;
  }
}
