// **
// * Variables
// **

$colors: (
  'black': #222,
  'white': #fbfbfb,
  'primary': #5f6e8b,
  'notfallrot': #f57e69,
  'grey': #f0f1f3,
  'orange': #f08a33,
  'gelb': #f69d28,
  'rot': #d52525,
);

$white: #fbfbfb;
$primary: #5f6e8b;
$notfallrot: #f57e69;
$grey: #5f6e8b;
$orange: #f08a33;
$gelb: #f69d28;
$rot: #d52525;

$xs: 600px;
$sm: 782px;
$md: 1024px;
$lg: 1240px;
$xl: 1460px;
